<template>
  <div>
    <background></background>
    <notification-bar></notification-bar>
    <div class="layout-horizontal">
      <main-menu class="layout-inflexible" title="系统管理" icon="fas fa-cog"
                 :pick-up.sync="pickUp" :current-tag.sync="currentTag">
        <main-menu-item icon="fas fa-user-friends" title="账号管理" tag="account-mgmt"
                        @click.native="clickAccountMgmtMenu"
        ></main-menu-item>
        <main-menu-item icon="fas fa-sitemap" title="组织管理" tag="organization-mgmt"
                        @click.native="clickOrganizationMgmtMenu"
        >
        </main-menu-item>
        <template v-if="$isSuperMgr()">
          <div class="divider"></div>
          <main-menu-item icon="fas fa-plug" title="插件管理" tag="plugin-mgmt"
                          @click.native="clickPluginMgmtMenu"
          ></main-menu-item>
        </template>
      </main-menu>
      <div class="layout-flexible font-align-left">
        <animated-router-view></animated-router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Background from "@/components/background/Background";
import NotificationBar from "@/components/notificationbar/NotificationBar";
import MainMenuItem from "@/components/mainmenu/MainMenuItem";
import MainMenu from "@/components/mainmenu/MainMenu";
import AnimatedRouterView from "@/components/animatedrouterview/AnimatedRouterView";
import httpapi from "@/assets/javascript/httpapi";

export default {
  name: "SystemSetting",
  mixins: [httpapi],
  components: {AnimatedRouterView, MainMenu, MainMenuItem, NotificationBar, Background},
  methods: {
    clickPluginMgmtMenu() {
      this.$router.push({
        name: 'plugin_list',
      })
    },
    clickAccountMgmtMenu() {
      this.$router.push({
        name: 'account_list',
      });
    },
    clickOrganizationMgmtMenu() {
      this.$router.push({
        name: 'organization_list',
      })
    }
  },
  data() {
    return {
      pickUp: false,

      currentTag: null,
    }
  }
}
</script>

<style scoped>

</style>